import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';

import { map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/@services/auth.service';

@Injectable()
export class HttpInspectorService implements HttpInterceptor {

    currentAuthToken = null;
    constructor(

        private authService: AuthService
    ) {
    
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // YOU CAN ALSO DO THIS
        return from(this.authService.getCurrentUser())
            .pipe(
                switchMap((currentUser:any) => {
                    if (currentUser && currentUser.token) {
                        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + currentUser.token) });
                    }
                    if (!request.headers.has('Content-Type')) {
                        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
                    }
                    return next.handle(request).pipe(
                        map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                // do nothing for now
                            }
                            return event;
                        }),

                    );
                })
            );


    }




}

<div class="loader">
    <mat-spinner *ngIf="isLoading"></mat-spinner>


</div>

<div class="container" *ngIf="!isLoading  && !authFailedMessage">
    <h1>Transactions</h1>
    <div class="serarch-box search-box">
        <input [formControl]="searchForm" id="search-input" type="text" placeholder="Search">
    </div>
    <table class="rwd-table">
        <tbody>
            <tr>
                <th>Provider</th>
                <th>Paid By </th>
                <th>Email </th>
                <th>Invoice Number</th>
                <th>Invoice Date</th>
                <th>Amount</th>
                <th>Transaction Id</th>
                <th>Status</th>

            </tr>
            <tr *ngFor="let  payment of filterPaymentHistories">
                <td style="width:125px"> {{ payment?.receiver_first_name? payment?.receiver_first_name+" "+payment?.receiver_last_name:"-"
                    }} </td>
                <td>{{payment?.first_name}} {{payment?.last_name}} </td>
                <td>{{payment?.email}} </td>
                <td>{{payment?.invoice_no}} </td>
                <td>{{payment?.created_at | date }} {{payment?.created_at | date:'shortTime'}} </td>
                <td>{{payment?.amount}} </td>
                <td>{{payment?.transaction_id}} </td>
                <td>{{payment?.status}} </td>
            </tr>

        </tbody>
    </table>

</div>

<div class="process-text" *ngIf="authFailedMessage">
    <h3>{{authFailedMessage}}</h3>
    <img style="   width: 100px;"
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Flat_cross_icon.svg/1200px-Flat_cross_icon.svg.png"
        alt="">
</div>
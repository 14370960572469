import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { CommonService } from '../@services/common.service';
import { PaymentService } from '../@services/payment.service';
import { AppConfig } from '../@app-config/app-config.constant';



@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit {
  userControl = new FormControl();
  isLoading = true;
  paymentForm: FormGroup;
  isSuccess = false;
  authFailedMessage;
  users = [];
  filteredOptions: Observable<any[]>;
  isProcessing = false;
  isPayByMyself = false;

  private paymentPerson = {
    pay_for_uuid: '',
    pay_by_uuid: ''
  }
  constructor(
    private router: Router,
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private fb: FormBuilder) {





  }
  private _filter(value: string): string[] {
    let user = [];
    // console.log(value)
    if (value && typeof value == 'string') {
      const filterValue = value.toLowerCase();
      user = this.users.filter(option => (option.user_first_name.toLowerCase().includes(filterValue)) || (option.user_last_name.toLowerCase().includes(filterValue)));
    } else {
      user = this.users;
    }

    return user;

  }



  private callPatientData(user_uuid: string) {
    this.isPayByMyself = true;

    const c1 = this.paymentService.getLoggedInUserInfo(user_uuid);
    const c2 = this.paymentService.getProfessionals();

    forkJoin([c1, c2]).subscribe((res: any) => {
      console.log(res)
      this.isLoading = false;
      if (res) {
        const model = this.createUserModel(res[0]);
        this.initForm(model)
        this.users = res[1];
      }
    })

    // this.paymentService.getLoggedInUserInfo(user_uuid).pipe(switchMap((res:any)=>{
    //   this.paymentService.getProfessionals()
    // }))
    // this.paymentService.getProfessionals().subscribe(res => {
    //   this.users = res;
    // })
  }


  private callProfessionalData() {
    this.paymentService.getUsers().subscribe((res: any) => {
      this.isLoading = false;
      this.users = res;
    })
  }
  ngOnInit(): void {

    this.filteredOptions = this.userControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
    this.initForm();


    if (this.route.snapshot.queryParams && this.route.snapshot.queryParams.session_id) {

      this.paymentService.getAuthInfo(this.route.snapshot.queryParams.session_id).subscribe((auth: any) => {


        if (auth) {

          if (auth.user_owner) { // if patient login
            this.paymentPerson.pay_by_uuid = auth.user_uuid;
            this.callPatientData(auth.user_uuid);
          } else {

            this.paymentPerson.pay_for_uuid = auth.user_uuid; // if professional or admin login
            this.callProfessionalData();

          }

        } else {
          this.authFailedMessage = auth.message;
        }
      }, er => {
        this.authFailedMessage = 'Authentication Failed';
        this.isLoading = false;
      })

    } else {
      this.authFailedMessage = 'Authentication Failed';
      this.isLoading = false;
    }



    // this.paymentService.checkpayment(this.route.snapshot.data.)
  }

  initForm(data?) {
    this.paymentForm = this.fb.group({
      Amount: [data && data.Amount || null, Validators.required],
      CardNumber: [data && data.CardNumber || '', Validators.required],
      CardHolderName: [data && data.CardHolderName || '', Validators.required],
      CardCode: [data && data.CardCode || '', Validators.required],
      ExMonth: ['', Validators.required],
      ExYear: ['', Validators.required],
      Email: [data && data.Email || '', Validators.required],
      City: [data && data.City || '', Validators.required],
      State: [data && data.State || '', Validators.required],
      ZipCode: [data && data.ZipCode || '', Validators.required],
      BillingAddress: [data && data.BillingAddress || '', Validators.required],
      FirstName: [data && data.FirstName || ''],
      LastName: [data && data.LastName || ''],


    })
  }

  onChangeMonth(e) {
    //  console.log(e.target.value)
    this.paymentForm.get('ExMonth').setValue(e.target.value);
  }


  private createUserModel(user) {
    const model = {
      Email: user.user_email,
      City: user.addr_city,
      State: user.addr_state,
      ZipCode: user.addr_zip,
      BillingAddress: user.addr_line1,
      FirstName: user.user_first_name,
      LastName: user.user_last_name,
    }
    return model;
  }
  onSelectUser(e: MatAutocompleteSelectedEvent) {
    // console.log(e.option.value)
    if (e.option.value) {
      this.userControl.setValue(e.option.value.user_first_name + ' ' + (e.option.value.user_last_name));

      if (!this.isPayByMyself) {
        this.isProcessing = true;
        this.paymentService.getUser(e.option.value.user_uuid).subscribe((user: any) => {
          //   console.log(user)
          this.isProcessing = false;
          if (user) {
            this.paymentPerson.pay_by_uuid = e.option.value.user_uuid;
            const model = this.createUserModel(user);
            this.initForm(model)
          }
        })
      } else {
        this.paymentPerson.pay_for_uuid = e.option.value.user_uuid;
      }

    }

  }
  onChangeYear(e) {
    this.paymentForm.get('ExYear').setValue(e.target.value);
  }


  checkValidation() {

    const requiredFileds = ['Amount', 'CardNumber', 'CardHolderName', 'ExMonth', 'ExYear', 'Email', 'CardCode', 'ZipCode', 'BillingAddress', 'City', 'State'];

    if (!this.paymentPerson.pay_by_uuid || !this.paymentPerson.pay_for_uuid) {
      this.commonService.showMessage(`User is not selected`, false);
      return;
    }


    for (let key in this.paymentForm.value) {
      console.log(key)
      if (requiredFileds.includes(key) && !this.paymentForm.value[key]) {

        if (key == 'ExMonth' || key == 'ExYear') {
          this.commonService.showMessage(`Expire date missing`, false);
        } else {
          this.commonService.showMessage(`${key} missing`, false);
        }

        break;
      }
    }
  }
  submit() {
    if (this.paymentForm.invalid) {
      this.checkValidation();
      return;
    }

    this.isProcessing = true;
    const model = {
      expiration_date: `${this.paymentForm.value.ExMonth}${this.paymentForm.value.ExYear}`,
      amount: Number(this.paymentForm.value.Amount),
      card_number: this.paymentForm.value.CardNumber,
      card_holder_name: this.paymentForm.value.CardHolderName,
      card_code: this.paymentForm.value.CardCode,
      email: this.paymentForm.value.Email,
      city: this.paymentForm.value.City,
      state: this.paymentForm.value.State,
      zip_code: this.paymentForm.value.ZipCode,
      billing_address: this.paymentForm.value.BillingAddress,
      pay_for_uuid: this.paymentPerson.pay_for_uuid,
      pay_by_uuid: this.paymentPerson.pay_by_uuid,
      first_name: this.paymentForm.value.FirstName,
      last_name: this.paymentForm.value.LastName,
      description: "mednoor payment"
    }

    // console.log(model)
    this.paymentService.paynow(model).subscribe((response: any) => {
      setTimeout(_ => {
        this.isProcessing = false;
      }, 500)
      if (response.success) {
        this.isSuccess = true;
        this.commonService.showMessage("Payment success", true)

      } else {
        this.commonService.showMessage(response.message, false)
      }
    })

  }


  createNew() {
    window.location.reload();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppConfig } from '../@app-config/app-config.constant';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PaymentService {


    API_URL = environment.API_URL;

    constructor(
        private authService: AuthService,
        private http: HttpClient) { }


    checkpayment(token) {
        return this.http.get(this.API_URL + '/authenticate-payment?token=' + token);
    }

    paynow(model) {
        //  console.log(model)
        return this.createPaymentHistory(model);
    }



    getProfessionals(): Observable<any> {
        return this.http.get(environment.API_URL + '/my-professional');

    }


    getAuthInfo(session_id: string) {

        return this.authService.getToken(session_id);
    }


    getLoggedInUserInfo(user_uuid: string) {
        return this.http.get(environment.USER_API + '/' + user_uuid)
    }


    getUsers() {
        return this.http.get(environment.USER_API + '/list?view=users')

    }


    getPaymentHistory(session_id) {
        return this.authService.getToken(session_id).pipe(switchMap(auth => {
            if (auth) {
                return this.http.get(environment.API_URL + '/payment-history').pipe(map((res: any) => {
                    return res && res.result && res.result.data && this.sortingDate(res.result.data) || [];
                }))

            } else {
                return [];
            }
        }))
    }


    sortingDate(data) {
        return data.sort((a, b) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        })
    }


    getUser(userId) {

        return this.http.get(environment.USER_API + '/' + userId)

    }


    createPaymentHistory(_value) {
        return this.http.post(this.API_URL + '/payment-history', _value);
    }
    updatePaymentHistory(transaction_id, p_id) {
        const model = {
            status: true,
            transaction_id: transaction_id
        }

        return this.http.post(this.API_URL + '/payment-history/update/' + p_id, model);
    }




}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from '../@app-config/app-config.constant';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    constructor(private http: HttpClient) { }

    getToken(session_id) {
        const model = {
            session_id: session_id
        }


        return this.http.post(environment.USER_API+'/token-exchange', model).pipe(map(res => {
        console.log(res)
            if (res) {
                this.setUser(res);
            }
            return res;
        },err=>{
            console.log(err)
        }))
    }

    private setUser(_currentUser) {
        localStorage.setItem('currentUser', JSON.stringify(_currentUser));
    }


    getCurrentUser() {
        return new Promise(function (resolve, reject) {
            if (localStorage.getItem('currentUser')) {
                const cu = JSON.parse(localStorage.getItem('currentUser'))
                resolve(cu); // when successful
            } else {
                resolve(null);  // when error
            }



        });
    }
}
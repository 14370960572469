import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from './shared/shared.module';
import { NumberInputDirective } from './shared/directives/numberonly.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpInspectorService } from './shared/http-with-injector/http-inspector.service';
import { ToastNoAnimationModule, ToastrModule } from 'ngx-toastr';
import { AppToastComponent } from './app-toast/app-toast.component';
import { FinancialHistoryComponent } from './financial-history/financial-history.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    AppComponent,
    NumberInputDirective,
    AppToastComponent,
    FinancialHistoryComponent,
    PaymentFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDialogModule,
    MatInputModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSnackBarModule,
    HttpClientModule,
    SharedModule,
    MatProgressSpinnerModule,
    ToastrModule.forRoot(),
    MatAutocompleteModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInspectorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }

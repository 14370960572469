import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {MatDialogModule} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';

import { SafeHtml } from './pipes/safeHtml.pipe';


@NgModule({
  declarations: [SafeHtml],
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    
  ],
  exports:[
    MatDialogModule,
    SafeHtml
  ]
})
export class SharedModule { }

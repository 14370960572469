import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { FinancialHistoryComponent } from './financial-history/financial-history.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';



const routes: Routes = [
  {
    path: '',
    component: PaymentFormComponent
  },
  {
    path: 'financial',
    component: FinancialHistoryComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { AppConfig } from '../@app-config/app-config.constant';

@Injectable({
    providedIn: 'root',
})
export class CommonService {

    // constructor(private _snackBar: MatSnackBar) { }
    @ViewChild(ToastContainerDirective, { static: true })
    toastContainer: ToastContainerDirective;

    constructor(private toastrService: ToastrService) { }
    ngOnInit() {
        this.toastrService.overlayContainer = this.toastContainer;
    }
    onClick() {
        this.toastrService.success('in div');
    }

    showMessage(message,success) {

        if(success){
            this.toastrService.success(message);
        }else{
            this.toastrService.error(message);
        }
      
    }
}
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { debounceTime, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { PaymentService } from '../@services/payment.service';
import { includes } from 'lodash';
@Component({
  selector: 'app-financial-history',
  templateUrl: './financial-history.component.html',
  styleUrls: ['./financial-history.component.scss']
})
export class FinancialHistoryComponent implements OnInit {
  isLoading = true;
  paymentHistories = [];
  filterPaymentHistories = [];
  authFailedMessage;
  searchForm: FormControl = new FormControl('');
   monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
  constructor(
    private paymentService: PaymentService,
    private router: Router,
    private route: ActivatedRoute
  ) { }


  private dateSearch(substring: string, date: string){
    const d = this.monthNames[new Date(date).getMonth()]+ " "+ new Date(date).getDate();
    return this.stringSearch(substring,d)
  }
  private stringSearch(substring: string, string: string) {
    string = String(string).toUpperCase();
    substring = String(substring).toUpperCase();
    // console.log(includes(string, substring))
    return includes(string, substring);
  }
  ngOnInit(): void {

    this.searchForm.valueChanges.pipe(
      debounceTime(100),
      map((obj) => obj),
      distinctUntilChanged()).subscribe(searchText => {
        // console.log(searchText)
        this.filterPaymentHistories = this.paymentHistories.filter(
          x => 
            
            this.stringSearch(searchText, x.receiver_first_name) ||
            this.stringSearch(searchText, x.receiver_last_name) ||
            this.stringSearch(searchText, x.first_name) ||
            this.stringSearch(searchText, x.last_name) ||
            this.stringSearch(searchText, x.invoice_no) ||
            this.stringSearch(searchText, x.status) ||
            this.stringSearch(searchText, x.amount) ||
            this.stringSearch(searchText, x.email) ||
            this.stringSearch(searchText, x.transaction_id) ||
            this.dateSearch(searchText, x.created_at)
          
         
        );

      })



    console.log(this.route.snapshot.queryParams)

    if (this.route.snapshot.queryParams && this.route.snapshot.queryParams.session_id) {
      this.paymentService.getPaymentHistory(this.route.snapshot.queryParams.session_id).subscribe((result: any) => {
        this.isLoading = false;
        console.log(result);
        if (result && result.length) {
          this.paymentHistories = result;
          this.filterPaymentHistories = this.paymentHistories;
        } else {

        }
      }, er => {
        this.authFailedMessage = 'Authentication Failed';
        this.isLoading = false;
      })
    } else {
      this.authFailedMessage = 'Authentication Failed';
      this.isLoading = false;
    }
  }

}

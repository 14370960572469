import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-toast',
  templateUrl: './app-toast.component.html',
  styleUrls: ['./app-toast.component.scss']
})
export class AppToastComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="payment-form">
    <div class="loader" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>
    <div class="container" *ngIf="!isLoading && !isSuccess && !authFailedMessage">


        <form [formGroup]="paymentForm">
            <div>
                <h1 style="text-align: center;">Mednoor Pay</h1>
            </div>


            <!-- <div class="inputBox">

                <select name="" id="" class="month-input" (change)="onSelectUser($event)">
                    <option value="month" selected disabled>Search User</option>
                    <option [value]="user.user_uuid" *ngFor="let user of users">{{user?.user_first_name}}
                        {{user?.user_last_name}} </option>

                </select>
            </div> -->





            <div class="">

                <mat-form-field style="width:100%" appearance="outline">
                    <mat-label> {{isPayByMyself? 'Search Professional ':'Search User'}} </mat-label>
                    <input type="text" placeholder="Pick one" matInput [formControl]="userControl"
                        [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                        (optionSelected)="onSelectUser($event)">
                        <mat-option *ngFor="let user of filteredOptions | async" [value]="user">
                            {{user?.user_first_name}} {{user?.user_last_name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <!-- <div class="inputBox">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of options" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
            </div> -->



            <div class="flexbox">
                <div class="inputBox">
                    <span>Amount</span>
                    <input formControlName="Amount" numberOnly class="card-number-input">
                </div>
                <div class="inputBox">
                    <span>Email</span>
                    <input formControlName="Email" type="email" class="card-number-input">
                </div>
            </div>
            <div class="inputBox">
                <span>card number</span>
                <input formControlName="CardNumber" type="text" class="card-number-input">
            </div>
            <div class="inputBox">
                <span>card holder</span>
                <input formControlName="CardHolderName" type="text" class="card-holder-input">
            </div>
            <div class="flexbox">
                <div class="inputBox">
                    <span>expiration mm</span>
                    <select name="" id="" class="month-input" (change)="onChangeMonth($event)">
                        <option value="month" selected disabled>month</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </select>
                </div>
                <div class="inputBox">
                    <span>expiration yy</span>
                    <select name="" id="" class="year-input" (change)="onChangeYear($event)">
                        <option value="year" selected disabled>year</option>
                        <option value="22">2022</option>
                        <option value="23">2023</option>
                        <option value="24">2024</option>
                        <option value="25">2025</option>
                        <option value="26">2026</option>
                        <option value="27">2027</option>
                        <option value="28">2028</option>
                        <option value="29">2029</option>
                        <option value="30">2030</option>
                    </select>
                </div>
                <div class="inputBox">
                    <span>cvv</span>
                    <input formControlName="CardCode" numberOnly class="cvv-input">
                </div>
            </div>
            <div class="inputBox">
                <span>Billing Address</span>
                <input type="text" formControlName="BillingAddress" class="card-number-input">
            </div>
            <div class="inputBox">
                <span>Country</span>
                <select name="" id="" class="month-input">
                    <option value="Afghanistan" title="AF">Afghanistan</option>
                    <option value="Albania" title="AL">Albania</option>
                    <option value="Algeria" title="DZA">Algeria</option>
                    <option value="American Samoa" title="ASM">American Samoa
                    </option>
                    <option value="Andorra" title="AND">Andorra</option>
                    <option value="Angola" title="AO">Angola</option>
                    <option value="Anguilla" title="AIA">Anguilla</option>
                    <option value="Antarctica" title="ATA">Antarctica</option>
                    <option value="Antigua and Barbuda" title="ATG">Antigua and
                        Barbuda
                    </option>
                    <option value="Argentina" title="ARG">Argentina</option>
                    <option value="Armenia" title="ARM">Armenia</option>
                    <option value="Aruba" title="ABW">Aruba</option>
                    <option value="Austalia" title="AUS">Austalia</option>
                    <option value="Austria" title="AUT">Austria</option>
                    <option value="Azerbaijan" title="AZE">Azerbaijan</option>
                    <option value="Bahamas" title="BHS">Bahamas</option>
                    <option value="Bahrain" title="BHR">Bahrain</option>
                    <option value="Bangladesh" title="BD">Bangladesh</option>
                    <option value="Barbados" title="BRB">Barbados</option>
                    <option value="Belarus" title="BLR">Belarus</option>
                    <option value="Belgium" title="BEL">Belgium</option>
                    <option value="Belize" title="BLZ">Belize</option>
                    <option value="Benin" title="BEN">Benin</option>
                    <option value="Bermuda" title="BMU">Bermuda</option>
                    <option value="Bhutan" title="BTN">Bhutan</option>
                    <option value="Bolivia" title="BOL">Bolivia</option>
                    <option value="Bosnia and Herzegovina" title="BIH">Bosnia and
                        Herzegovina
                    </option>
                    <option value="Botswana" title="BWA">Botswana</option>
                    <option value="Brazil-" title="BRA">Brazil-</option>
                    <option value="British Indian Ocean Territory" title="IOT">
                        British Indian Ocean Territory
                    </option>
                    <option value="British Virgin Islands" title="VGB">British
                        Virgin Islands
                    </option>
                    <option value="Brunei" title="BRN">Brunei</option>
                    <option value="Bulgaria" title="BGR">Bulgaria</option>
                    <option value="Burkina Faso" title="BFA">Burkina Faso</option>
                    <option value="Burundi" title="BDI">Burundi</option>
                    <option value="Cambodia" title="KHM">Cambodia</option>
                    <option value="Cameroon" title="CMR">Cameroon</option>
                    <option value="Canada" title="CAN">Canada</option>
                    <option value="Cape Verde" title="CPV">Cape Verde</option>
                    <option value="Cayman Islands" title="CYM">Cayman Islands
                    </option>
                    <option value="Central African Republic" title="CAF">Central
                        African Republic
                    </option>
                    <option value="Chad" title="TCD">Chad</option>
                    <option value="Chile" title="CHL">Chile</option>
                    <option value="China" title="CHN">China</option>
                    <option value="Christmas Island" title="CXR">Christmas Island
                    </option>
                    <option value="Cocos Islands" title="CCK">Cocos Islands</option>
                    <option value="Colombia" title="COL">Colombia</option>
                    <option value="Comoros" title="COM">Comoros</option>
                    <option value="Cook Islands" title="COK">Cook Islands</option>
                    <option value="Costa Rica" title="CRI">Costa Rica</option>
                    <option value="Croatia" title="HRV">Croatia</option>
                    <option value="Cuba" title="CUB">Cuba</option>
                    <option value="Curacao" title="CUW">Curacao</option>
                    <option value="Cyprus" title="CYP">Cyprus</option>
                    <option value="Czech Republic" title="CZE">Czech Republic
                    </option>
                    <option value="Democratic Republic of the Congo" title="COD">
                        Democratic Republic of the Congo
                    </option>
                    <option value="Denmark" title="DNK">Denmark</option>
                    <option value="Djibouti" title="DJI">Djibouti</option>
                    <option value="Dominica" title="DMA">Dominica</option>
                    <option value="Dominican Republic" title="DOM">Dominican
                        Republic
                    </option>
                    <option value="East Timor" title="TLS">East Timor</option>
                    <option value="Ecuador" title="ECU">Ecuador</option>
                    <option value="Egypt" title="EGY">Egypt</option>
                    <option value="El Salvador" title="SLV">El Salvador</option>
                    <option value="Equatorial Guinea" title="GNQ">Equatorial
                        Guinea
                    </option>
                    <option value="Eritrea" title="ERI">Eritrea</option>
                    <option value="Estonia" title="EST">Estonia</option>
                    <option value="Ethiopia" title="ETH">Ethiopia</option>
                    <option value="Falkland Islands" title="FLK">Falkland Islands
                    </option>
                    <option value="Faroe Islands" title="FRO">Faroe Islands</option>
                    <option value="Fiji" title="FJI">Fiji</option>
                    <option value="Finland" title="Fin">Finland</option>
                    <option value="France" title="FRA">France</option>
                    <option value="French Polynesia" title="PYF">French Polynesia
                    </option>
                    <option value="Gabon" title="GAB">Gabon</option>
                    <option value="Gambia" title="GMB">Gambia</option>
                    <option value="Georgia" title="GEO">Georgia</option>
                    <option value="Germany" title="GER">Germany</option>
                    <option value="Ghana" title="GHA">Ghana</option>
                    <option value="Gibraltar" title="GIB">Gibraltar</option>
                    <option value="Greece" title="GRC">Greece</option>
                    <option value="Greenland" title="GRL">Greenland</option>
                    <option value="Grenada" title="GRD">Grenada</option>
                    <option value="Guam" title="GUM">Guam</option>
                    <option value="Guatemala" title="GTM">Guatemala</option>
                    <option value="Guernsey" title="GGY">Guernsey</option>
                    <option value="Guinea" title="GIN">Guinea</option>
                    <option value="Guinea-Bissau" title="GNB">Guinea-Bissau</option>
                    <option value="Guyana" title="GUY">Guyana</option>
                    <option value="Haiti" title="HTI">Haiti</option>
                    <option value="Honduras" title="HND">Honduras</option>
                    <option value="Hong Kong" title="HKG">Hong Kong</option>
                    <option value="Hungary" title="HUN">Hungary</option>
                    <option value="Iceland" title="ISL">Iceland</option>
                    <option value="India" title="IND">India</option>
                    <option value="Indonesia" title="IDN">Indonesia</option>
                    <option value="Iran" title="IRN">Iran</option>
                    <option value="Iraq" title="IRQ">Iraq</option>
                    <option value="Ireland" title="IRL">Ireland</option>
                    <option value="Isle of Man" title="IMN">Isle of Man</option>
                    <option value="Israel" title="ISR">Israel</option>
                    <option value="Italy" title="ITA">Italy</option>
                    <option value="Ivory Coast" title="CIV">Ivory Coast</option>
                    <option value="Jamaica" title="JAM">Jamaica</option>
                    <option value="Japan" title="JPN">Japan</option>
                    <option value="Jersey" title="JEY">Jersey</option>
                    <option value="Jordan" title="JOR">Jordan</option>
                    <option value="Kazakhstan" title="KAZ">Kazakhstan</option>
                    <option value="Kenya" title="KEN">Kenya</option>
                    <option value="Kiribati" title="KIR">Kiribati</option>
                    <option value="Kosovo" title="XKX">Kosovo</option>
                    <option value="Kuwait" title="KWT">Kuwait</option>
                    <option value="Kyrgyzstan" title="KGZ">Kyrgyzstan</option>
                    <option value="Laos" title="LAO">Laos</option>
                    <option value="Latvia" title="LVa">Latvia</option>
                    <option value="Lebanon" title="LBN">Lebanon</option>
                    <option value="Lesotho" title="LSO">Lesotho</option>
                    <option value="Liberia" title="LBR">Liberia</option>
                    <option value="Libya" title="LBY">Libya</option>
                    <option value="Liechtenstein" title="LIE">Liechtenstein</option>
                    <option value="Lithuania" title="LTU">Lithuania</option>
                    <option value="Luxembourg" title="LUX">Luxembourg</option>
                    <option value="Macau" title="MAC">Macau</option>
                    <option value="Macedonia" title="MKD">Macedonia</option>
                    <option value="Malawi" title="MWI">Malawi</option>
                    <option value="Malaysia" title="MYS">Malaysia</option>
                    <option value="Maldives" title="MDV">Maldives</option>
                    <option value="Mali" title="MLI">Mali</option>
                    <option value="Malta" title="MLT">Malta</option>
                    <option value="Marshall Islands" title="MHL">Marshall Islands
                    </option>
                    <option value="Mauritania" title="MRT">Mauritania</option>
                    <option value="Mauritius" title="MUS">Mauritius</option>
                    <option value="Mayotte" title="MYT">Mayotte</option>
                    <option value="Mexico" title="Mex">Mexico</option>
                    <option value="Micronesia" title="FSM">Micronesia</option>
                    <option value="Moldova" title="MDA">Moldova</option>
                    <option value="Monaco" title="MCO">Monaco</option>
                    <option value="Mongolia" title="MNG">Mongolia</option>
                    <option value="Montenegro" title="MNE">Montenegro</option>
                    <option value="Montserrat" title="MSR">Montserrat</option>
                    <option value="Morocco" title="MAR">Morocco</option>
                    <option value="Mozambique" title="MOZ">Mozambique</option>
                    <option value="Myanmar" title="MMR">Myanmar</option>
                    <option value="Namibia" title="NAM">Namibia</option>
                    <option value="Nauru" title="NRU">Nauru</option>
                    <option value="Nepal" title="NPL">Nepal</option>
                    <option value="Netherlands" title="NLD">Netherlands</option>
                    <option value="Netherlands Antilles" title="ANT">Netherlands
                        Antilles
                    </option>
                    <option value="New Caledonia" title="NCL">New Caledonia</option>
                    <option value="New Zealand" title="NZL">New Zealand</option>
                    <option value="Nicaragua" title="NIC">Nicaragua</option>
                    <option value="Niger" title="NER">Niger</option>
                    <option value="Nigeria" title="NGA">Nigeria</option>
                    <option value="North Korea" title="PRK">North Korea</option>
                    <option value="North Mariana Islands" title="MNP">North Mariana
                        Islands
                    </option>
                    <option value="Norway" title="NOR">Norway</option>
                    <option value="Oman" title="OMN">Oman</option>
                    <option value="Pakistan" title="PK">Pakistan</option>
                    <option value="Palau" title="PLW">Palau</option>
                    <option value="Palestine" title="PSE">Palestine</option>
                    <option value="Panama" title="PAN">Panama</option>
                    <option value="Papua New Guinea" title="PNG">Papua New Guinea
                    </option>
                    <option value="Paraguay" title="PRY">Paraguay</option>
                    <option value="Peru" title="PER">Peru</option>
                    <option value="Philippines" title="PHL">Philippines</option>
                    <option value="Pitcairn" title="PCN">Pitcairn</option>
                    <option value="Poland" title="POL">Poland</option>
                    <option value="Portugal" title="PRT">Portugal</option>
                    <option value="Puerto Rico" title="PRI">Puerto Rico</option>
                    <option value="Qatar" title="QAT">Qatar</option>
                    <option value="Republic of the Congo" title="COG">Republic of
                        the Congo
                    </option>
                    <option value="Romania" title="ROU">Romania</option>
                    <option value="Russia" title="RU">Russia</option>
                    <option value="Rwanda" title="RWA">Rwanda</option>
                    <option value="Saint Barthelemy" title="BLM">Saint Barthelemy
                    </option>
                    <option value="Saint Helena" title="SHN">Saint Helena</option>
                    <option value="Saint Kitts and Nevis" title="KNA">Saint Kitts
                        and Nevis
                    </option>
                    <option value="Saint Lucia" title="LCA">Saint Lucia</option>
                    <option value="Saint Martin" title="MAF">Saint Martin</option>
                    <option value="Saint Pierre and Miquelon" title="SPM">Saint
                        Pierre and Miquelon
                    </option>
                    <option value="Saint Vincent and the Grenadines" title="VCT">
                        Saint Vincent and the Grenadines
                    </option>
                    <option value="Samoa" title="WSM">Samoa</option>
                    <option value="San Marino" title="SMR">San Marino</option>
                    <option value="Sao Tome and Principe" title="STP">Sao Tome and
                        Principe
                    </option>
                    <option value="Saudi Arabia" title="SAU">Saudi Arabia</option>
                    <option value="Seirra Leone" title="SLE">Seirra Leone</option>
                    <option value="Senegal" title="SEN">Senegal</option>
                    <option value="Serbia" title="SRB">Serbia</option>
                    <option value="Seychelles" title="SYC">Seychelles</option>
                    <option value="Singapore" title="SGP">Singapore</option>
                    <option value="Sint Maarten" title="SXM">Sint Maarten</option>
                    <option value="Slovakia" title="SVK">Slovakia</option>
                    <option value="Slovania" title="SVN">Slovania</option>
                    <option value="Solomon Islands" title="SLB">Solomon Islands
                    </option>
                    <option value="Somalia" title="SOM">Somalia</option>
                    <option value="South Africa" title="SA">South Africa</option>
                    <option value="South Korea" title="KOR">South Korea</option>
                    <option value="South Sudan" title="SSD">South Sudan</option>
                    <option value="Spain" title="ESP">Spain</option>
                    <option value="Sri Lanka" title="LKA">Sri Lanka</option>
                    <option value="Sudan" title="SDN">Sudan</option>
                    <option value="Suriname" title="SUR">Suriname</option>
                    <option value="Svalbard and Jan Mayen" title="SJM">Svalbard and
                        Jan Mayen
                    </option>
                    <option value="Swaziland" title="SWZ">Swaziland</option>
                    <option value="Sweden" title="SWE">Sweden</option>
                    <option value="Switzerland" title="CHE">Switzerland</option>
                    <option value="Syria" title="SYR">Syria</option>
                    <option value="Taiwan" title="TWN">Taiwan</option>
                    <option value="Tajikistan" title="TJK">Tajikistan</option>
                    <option value="Tanzania" title="TZA">Tanzania</option>
                    <option value="Thailand" title="THA">Thailand</option>
                    <option value="Togo" title="TGO">Togo</option>
                    <option value="Tokelau" title="TKL">Tokelau</option>
                    <option value="Tonga" title="TON">Tonga</option>
                    <option value="Trinidad and Tobago" title="TTO">Trinidad and
                        Tobago
                    </option>
                    <option value="Tunisia" title="TUN">Tunisia</option>
                    <option value="Turkey" title="TK">Turkey</option>
                    <option value="Turks and Caicos Islands" title="TCA">Turks and
                        Caicos Islands
                    </option>
                    <option value="Turmenistan" title="TKM">Turmenistan</option>
                    <option value="Tuvalu" title="TUV">Tuvalu</option>
                    <option value="U.S. Virgine Islands" title="VIR">U.S. Virgine
                        Islands
                    </option>
                    <option value="Uganda" title="UGA">Uganda</option>
                    <option value="Ukrain" title="UKR">Ukrain</option>
                    <option value="United Arab Emirates" title="ARE">United Arab
                        Emirates
                    </option>
                    <option value="United Kingdom" title="UK">United Kingdom
                    </option>
                    <option value="Uruguay" title="URY">Uruguay</option>
                    <option value="USA" title="USA" selected="selected">USA</option>
                    <option value="Uzbeckistan" title="UZB">Uzbeckistan</option>
                    <option value="Vanuatu" title="VUT">Vanuatu</option>
                    <option value="Vatican" title="VAT">Vatican</option>
                    <option value="Venezuela" title="VEN">Venezuela</option>
                    <option value="Vietnam" title="VNM">Vietnam</option>
                    <option value="Wallis and Futuna" title="WLF">Wallis and
                        Futuna
                    </option>
                    <option value="Western Sahara" title="ESH">Western Sahara
                    </option>
                    <option value="Yemen" title="YEM">Yemen</option>
                    <option value="Zambia" title="ZMB">Zambia</option>
                    <option value="Zimbabwe" title="ZWE">Zimbabwe</option>
                </select>
            </div>
            <div class="flexbox">
                <div class="inputBox">
                    <span>City</span>
                    <input type="text" formControlName="City" class="card-holder-input">
                </div>
                <div class="inputBox">
                    <span>State</span>
                    <input type="text" formControlName="State" class="card-holder-input">
                </div>
                <div class="inputBox">
                    <span>ZipCode</span>
                    <input type="text" formControlName="ZipCode" class="cvv-input">
                </div>
            </div>
            <button type="submit" value="submit" class="submit-btn" (click)="submit()">Submit </button>


            <div class="form-loader" *ngIf="isProcessing">
                <mat-spinner></mat-spinner>
            </div>
        </form>



    </div>


    <div class="process-text" *ngIf="isSuccess">
        <h3>Payment Success</h3>
        <img style="   width: 100px;"
            src="https://cdn0.iconfinder.com/data/icons/social-messaging-ui-color-shapes-3/3/31-512.png" alt="">
        <div>
            <button (click)="createNew()" class="btn btn-primary new-btn">Create New transaction</button>
        </div>
    </div>

    <div class="process-text" *ngIf="authFailedMessage">
        <h3>{{authFailedMessage}}</h3>
        <img style="   width: 100px;"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Flat_cross_icon.svg/1200px-Flat_cross_icon.svg.png"
            alt="">
    </div>
</div>